import React, { useState } from 'react';
import { IonAlert, IonAvatar, IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { person, mail, phoneLandscape, location, flameOutline, checkmarkCircleOutline, logOutOutline, earth } from 'ionicons/icons';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import './Settings.css';
import { Api } from '../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import ToolbarComponent from '../components/ToolbarComponent';

interface LoginPageProps extends RouteComponentProps {
  api: Api
}

export const getHostName = function (url: any){
  const extractedValue = url.match(/\/\/(.*?)\./)[1];
  return extractedValue;
}

const Settings: React.FC<LoginPageProps> = ({api}) => {
  const history = useHistory();
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const navigateToAboutPage = () => {
    history.push('/about');
  };

  
  
  return (
    <IonPage>
      <IonHeader >
        <ToolbarComponent icon={faCog} title='Settings' backbutton={false} backlink=''/>
      </IonHeader>
      <IonContent fullscreen className="custom-content">
      
        <IonCard>
          <IonCardHeader>
          
            <IonCardSubtitle>User Profile</IonCardSubtitle>
            <IonCardTitle>
              {api.authentication.user?.name}
              </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList lines="full">
              <IonItem>
                <IonIcon slot="start" icon={mail} />
                <IonLabel>Email</IonLabel>
                <IonLabel slot="end">{api.authentication.user?.email}</IonLabel>
              </IonItem>
              <IonItem>
                <IonIcon slot="start" icon={flameOutline} />
                <IonLabel>Active</IonLabel>
                <IonLabel slot="end"><IonIcon slot="start" icon={checkmarkCircleOutline} size="large" color='tertiary' /></IonLabel>
              </IonItem>
              <IonItem>
                <IonIcon slot="start" icon={earth} />
                <IonLabel >Portal</IonLabel>
                <IonLabel slot="end"><IonBadge>{getHostName(api.portalAPI)}</IonBadge></IonLabel>
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>
        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle>Legend</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            
            <IonList lines="none" className='p-2 d-flex'>
              <div className='legend-container background-accepted'></div>&nbsp;<div>Docket Accepted</div>
            </IonList>
            <IonList lines="none" className='p-2 d-flex'>
              <div className='legend-container background-denied'></div>&nbsp;<div>Docket Denied</div>
            </IonList>
            <IonList lines="none" className='p-2 d-flex'>
              <div className='legend-container background-incomplete'></div>&nbsp;<div>Docket Incomplete</div>
            </IonList>
            <IonList lines="none" className='p-2 d-flex'>
              <div className='legend-container background-unsigned'></div>&nbsp;<div>Docket Unsigned</div>
            </IonList>
            <IonList lines="none" className='p-2 d-flex'>
              <div className='legend-container background-supervisor-rejected'></div>&nbsp;<div>Docket Supervisor Rejected</div>
            </IonList>
            <IonList lines="none" className='p-2 d-flex'>
              <div className='legend-container background-today'></div>&nbsp;<div>Docket Signed</div>
            </IonList>
          </IonCardContent>
        </IonCard>
        <div className="about-button-container">
          <IonButton expand="full" color="medium" onClick={() => setIsAlertOpen(true) }><IonIcon slot="start" icon={logOutOutline} size="large" color='dark' />Logout</IonButton>
          {/* <IonButton expand="full" color="light" onClick={navigateToAboutPage}>About</IonButton> */}
        </div>
      </IonContent>
      <IonAlert
          header="Confirmation"
          subHeader="Are you sure you want to logout?"
          trigger="submit-alert"
          isOpen={isAlertOpen}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
              },
            },
            {
              text: 'Confirm',
              role: 'confirm',
              handler: (e) => {
                api.logOut();
                history.push('/timesheet');
              },
            },
          ]}
          onDidDismiss={() => setIsAlertOpen(false)}
        ></IonAlert>
    </IonPage>
  );
};

export default Settings;
