import React, { useEffect, useRef, useState } from 'react';
import { IonContent, IonLoading, IonHeader, IonPage, IonCard, IonDatetime, IonCardContent, IonCol, IonGrid, IonIcon, IonRow, IonLabel, IonButton, IonInput, IonItem, IonDatetimeButton, IonModal, IonRefresher, IonRefresherContent, IonList, IonToast, IonAlert, IonTextarea, IonAccordion, IonAccordionGroup, IonCheckbox, useIonAlert, useIonModal } from '@ionic/react';
import { RouteComponentProps } from "react-router-dom";
import { timeOutline, locationOutline, businessOutline, calendarOutline, briefcaseOutline, mailOutline, callOutline, bookOutline, warning, trash, checkmarkCircle, closeCircle } from 'ionicons/icons';
import moment from 'moment';
import { Api } from '../services/api';
import './RosterDetail.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBowlFood, faBox, faBoxes, faCheckCircle, faExclamationCircle, faFilePdf, faHatHard, faImage, faPlane, faTimesCircle, faTruckMonster, faUserClock, faUserNinja } from '@fortawesome/free-solid-svg-icons';
import ToolbarComponent from '../components/ToolbarComponent';
import FileViewComponent from '../components/FileViewComponent';
interface RosterDetailProps extends RouteComponentProps<{ id: any }> {
  api: Api
}

interface Equipment {
  id: number;
  start_time: string | undefined;
  end_time: string | undefined;
  break_hours: string | undefined;
}

interface Product {
  id: number;
  entry: string | undefined;
}

const RosterDetail: React.FC<RosterDetailProps> = ({ api, match }) => {
  const { id } = match.params;
  const [present] = useIonAlert();


  const [startTime, setStartTime] = useState<string | any>('');
  const [endTime, setEndTime] = useState<string | any>('');
  const [breakStartTime, setBreakStartTime] = useState<string | string[]>('');
  const [breakEndTime, setBreakEndTime] = useState<string | string[]>('');
  const [selectedMinute, setSelectedMinute] = useState<string>('');
  const [shiftCompleted, setShiftCompleted] = useState<boolean>(false);
  const [equipmentList, setEquipmentList] = useState<Equipment[] | undefined>([]);
  const [productList, setProductList] = useState<Product[] | undefined>([]);
  const [comment, setComment] = useState<string | any>('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [attachment, setAttachment] = useState<File | any>();

  const [isOpen, setIsOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const minuteValuesInterval = "0,5,10,15,20,25,30,35,40,45,50,50,55";
  const [loading, setLoading] = useState<boolean>(false);
  const [breaktimeFields, setbreakTimeFields] = useState([{ startTime: '', endTime: '' }]);
  const [checkedEquipments, setCheckedEquipments] = useState<{ [key: number]: boolean }>({});
  const [fileName, setFileName] = useState('');
  const [fileContent, setFileContent] = useState('');
  const [fileType, setFileType] = useState('');

  const handleCheckboxChange = (equipmentId: number) => {
    setCheckedEquipments(prevState => ({
      ...prevState,
      [equipmentId]: !prevState[equipmentId],
    }));
  };
  useEffect(() => {
    handleRemoveField(0);
  }, []);
  useEffect(() => {
    if (api.authentication?.user?.id) {
      api.rosters.getRosterDetail(id).then((data: any) => {
        setStartTime(data?.start_time.split('+')[0]);
        setEndTime(data?.end_time.split('+')[0]);
        const updatedEquipmentList = data?.job_allocation?.equipment?.map((equipment: any) => {
          return { id: equipment.id, start_time: data?.start_time.split('+')[0], end_time: data?.end_time.split('+')[0], break_hours: '0' }
        });
        const updatedProductList = data?.job_allocation?.products?.map((product: any) => {
          return { id: product.id, entry: '' }
        });
        setEquipmentList(updatedEquipmentList);
        setProductList(updatedProductList);

        if (data?.completed) {
          setShiftCompleted(true);
        }
      });
    }
  }, [id]);

  const handleFileChange = () => {
    if (fileInputRef.current?.files) {
      const file = fileInputRef.current.files[0];
      if (file) {
        setAttachment(file);
        // Handle file upload logic here
      }
    }
  };


  const handleStartTimeChange = (event: CustomEvent, equipmentId: number) => {
    equipmentList?.map((equipment, index) => {
      if (equipment.id === equipmentId) {
        equipmentList[index].start_time = event.detail.value;
      }
    });
  };

  const handleEndTimeChange = (event: CustomEvent, equipmentId: number) => {
    equipmentList?.map((equipment, index) => {
      if (equipment.id === equipmentId) {
        equipmentList[index].end_time = event.detail.value;
      }
    });

  };

  const handleBreakTimeChange = (event: CustomEvent, equipmentId: number) => {
    equipmentList?.map((equipment, index) => {
      function convertToMinutes(timeString: string) {
        const [hours, minutes] = timeString.split(':').map(Number);
        return (hours * 60 + minutes).toString();
      }

      if (equipment.id === equipmentId) {
        equipmentList[index].break_hours = convertToMinutes(event.detail.value);
      }
    });

  };

  const handleProductInput = (event: CustomEvent, productId: number) => {
    // Handle the updated value here
    productList?.map((product, index) => {
      if (product.id === productId) {
        productList[index].entry = event.detail.value;
      }
    });
  };


  const handleEmailClick = (email: any) => {
    window.location.href = `mailto:${email}`;
  };

  const handlePhoneClick = (phone: any) => {
    window.location.href = `tel:${phone}`;
  };

  const handleSubmit = async (e: any) => {
    // e.preventDefault();
    const filteredEquipmentList = equipmentList?.filter(equipment => checkedEquipments[equipment.id]);
    setLoading(true);
    let timesheetEntry = {
      timesheet_id: api.rosters?.rosterDetail?.id,
      user_id: api.rosters?.rosterDetail?.user_id,
      supervisor_id: api.rosters?.rosterDetail?.job_allocation?.supervisor_id,
      date: api.rosters?.rosterDetail?.date,
      start_time: startTime,
      end_time: endTime,
      // break_start_time: breakStartTime,
      // break_end_time: breakEndTime,
      break_hours: selectedMinute,
      equipment_entries: filteredEquipmentList,
      product_entries: productList,
      comment: comment,
      signature: null,
      file_upload: attachment || null,
      breaktimeFields: breaktimeFields,
      timesheet_entry_equipments: [],
      timesheet_entry_breaks: [],
      supervisor_accepted: null,
      supervisor_comment: ''
    }

    const response = await api.timesheetEntries.addTimesheetEntry(timesheetEntry);
    if (!response) {
      setIsOpen(true);
    } else {
      if (api.authentication?.user?.id) {
        await api.rosters.getRosters(api.authentication?.user?.id);
      }
      setShiftCompleted(true);
    }
    setLoading(false);

  };

  const handleMinuteChange = (event: CustomEvent) => {
    setSelectedMinute(event.detail.value);
  };

  const compareDate = (d1: any, d2: any) => {

    if (d1 <= d2) {
      return true;
    } else {
      return false;
    }
  }

  const openFileInBrowser = async (url: string, id: number) => {
    // const uri = api.portalAPI?.replace('/api', '') + url;
    // window.open(uri, '_blank');
    setLoading(true);
    const response = await api.rosters.accessFile(id);
    if(!response){
      present('Something went wrong', [{ text: 'OK', handler: () => { } }]);
    }else{
      setFileName(response.name);
      if (response) {
        const blob = new Blob([new Uint8Array(atob(response.content).split('').map(char => char.charCodeAt(0)))], { type: response.type });
        const url = URL.createObjectURL(blob);
        setFileContent(url);
      }
      // setFileContent(atob(response.content));
      setFileType(response.type);
      presentFileViewModal();
    }
    
    setLoading(false);
  };

  const [presentFileViewModal, dismiss] = useIonModal(FileViewComponent, {
    api,
    fileName: fileName,
    fileContent: fileContent,
    fileType: fileType,
    onDismiss: () => {dismiss()},
  });

  const handleChange = (index: number, field: string, value: string | any) => {

    const newTimeFields: any = [...breaktimeFields];
    newTimeFields[index][field] = value?.split("T")[1];
    setbreakTimeFields(newTimeFields);
  };

  const handleAddField = () => {
    setbreakTimeFields([...breaktimeFields, { startTime: '', endTime: '' }]);
  };

  const handleRemoveField = (index: number) => {
    const newTimeFields = [...breaktimeFields];
    newTimeFields.splice(index, 1);
    setbreakTimeFields(newTimeFields);
  };

  function calculatePayrunHours(entry: any) {
    // Calculate total worked hours
    let totalHours = 0;
    let startTime = formatTime(entry.start_time);
    let endTime = formatTime(entry.end_time);
    let startMinutes = parseInt(startTime.substring(0, 2)) * 60 + parseInt(startTime.substring(3, 5));
    let endMinutes = parseInt(endTime.substring(0, 2)) * 60 + parseInt(endTime.substring(3, 5));

    if (endMinutes < startMinutes) {
      // Add 24 hours (1440 minutes) to the end time
      endMinutes += 1440;
    }

    let diff = Math.abs(endMinutes - startMinutes);
    totalHours += diff / 60;

    // Calculate total break hours
    let totalBreakHours = 0;
    entry?.timesheet_entry_breaks?.forEach((breakEntry: any) => {
      if (breakEntry.start_time && breakEntry.end_time) {
        let breakStartTime = formatTime(breakEntry.start_time);
        let breakEndTime = formatTime(breakEntry.end_time);
        let startBreakMinutes = parseInt(breakStartTime.substring(0, 2)) * 60 + parseInt(breakStartTime.substring(3, 5));
        let endBreakMinutes = parseInt(breakEndTime.substring(0, 2)) * 60 + parseInt(breakEndTime.substring(3, 5));

        if (endBreakMinutes < startBreakMinutes) {
          // Add 24 hours (1440 minutes) to the end time
          endBreakMinutes += 1440;
        }

        let diffBreak = Math.abs(endBreakMinutes - startBreakMinutes);
        let breakHours = diffBreak / 60;
        totalBreakHours += breakHours;
      }
    });

    // Calculate pay run hours
    let payrunHours = totalHours - totalBreakHours;
    return parseFloat(payrunHours.toFixed(2));
  }

  function formatTime(time: string) {
    // Assuming time is in the format "HH:mm:ss"
    // Extract only the "HH:mm" part
    return time.substring(0, 5);
  }

  function calculateEquipmentHours(equipment: any) {
    let totalEquipmentHours = 0;
    let equipmentStartTime = formatTime(equipment.start_time);
    let equipmentEndTime = formatTime(equipment.end_time);

    let equipmentStartMinutes = parseInt(equipmentStartTime.substring(0, 2)) * 60 + parseInt(equipmentStartTime.substring(3, 5));
    let equipmentEndMinutes = parseInt(equipmentEndTime.substring(0, 2)) * 60 + parseInt(equipmentEndTime.substring(3, 5));

    if (equipmentEndMinutes < equipmentStartMinutes) {
      // Add 24 hours (1440 minutes) to the end time
      equipmentEndMinutes += 1440;
    }

    let diffEquipment = Math.abs(equipmentEndMinutes - equipmentStartMinutes);
    console.log("aa", diffEquipment, equipment.break_hours);
    
    // Subtract break hours (convert to minutes and subtract)
    totalEquipmentHours = (diffEquipment - parseInt(equipment.break_hours)) / 60;

    return parseFloat(totalEquipmentHours.toFixed(2)); // Round to 2 decimal places
  }

  function isSameDayOrFuture(){
    
    if((moment(moment().format('Y-MM-DD')).isSame(moment(api.rosters?.rosterDetail?.date).format('Y-MM-DD')))){
      return true;
    }else if (moment((moment().format('Y-MM-DD'))).isAfter(moment(api.rosters?.rosterDetail?.date))){
      if(moment(moment().format('Y-MM-DD')).isSameOrBefore(moment(api.rosters?.rosterDetail?.date).add(api.timeEntryLimit, 'days'))){
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
  }


  return (
    <IonPage>
      <IonHeader>
        <ToolbarComponent icon={null} title='' backbutton={true} backlink='' />

      </IonHeader>
      <IonContent className="custom-content">
        <IonRefresher slot="fixed" onIonRefresh={(e) => api.rosters.doRefresh(e, api.authentication?.user?.id)}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonGrid className="container mt-3">
          <IonRow className="date-container">
            <div> <IonIcon aria-hidden="true" icon={calendarOutline} /> {moment(api.rosters?.rosterDetail?.date).format("D MMM YY")} </div>
          </IonRow>
          <IonRow className="time-container">
            <div className="time-section"> <IonIcon aria-hidden="true" icon={timeOutline} />{moment(api.rosters?.rosterDetail?.start_time, "HH:mm:ss").format("hh:mm A")} - {moment(api.rosters?.rosterDetail?.end_time, "HH:mm:ss").format("hh:mm A")}</div>
          </IonRow>

          <hr />
          <IonRow className="job-allo-container">
            <IonCol className="col-12">
              <IonLabel className='font-weight-bold text-muted' >Supervisor: </IonLabel>
              <IonLabel className='font-weight-bold' >{(api.rosters?.rosterDetail?.job_allocation?.supervisor?.name) ? (<><FontAwesomeIcon icon={faUserNinja} />  {api.rosters?.rosterDetail?.job_allocation?.supervisor?.name}</>) : (<IonLabel> None</IonLabel>)}</IonLabel>
              <div className="event-details">
                <div className="">   </div>
              </div>
            </IonCol>
          </IonRow>
          <hr />
          <IonAccordionGroup>
            <IonAccordion value="first">
              <IonItem slot="header">
                <IonLabel>Job Task</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                <IonRow className="job-allo-container">
                  <IonCol className="col-12">
                    <IonLabel className='font-weight-bold text-muted' >Task</IonLabel>
                    <div className="event-details">
                      <div className=""> <FontAwesomeIcon icon={faHatHard} /> {api.rosters?.rosterDetail?.job_task?.job_title}</div>
                      {api.rosters?.rosterDetail?.job_task?.job_description && (
                        <div className='instruction-container mt-2'>
                          <div className='d-flex align-items-center'><IonIcon aria-hidden="true" icon={bookOutline} />&nbsp; <span className='text-muted' style={{ fontSize: 12 }}>Task Description</span></div>
                          <div dangerouslySetInnerHTML={{ __html: api.rosters?.rosterDetail?.job_task?.job_description }}></div>
                        </div>
                      )}
                    </div>
                  </IonCol>
                </IonRow>
              </div>
            </IonAccordion>
            <IonAccordion value="second">
              <IonItem slot="header">
                <IonLabel>Job Detail</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                <IonRow className="job-allo-container">
                  <IonCol className="col-12">
                    <IonLabel className='font-weight-bold text-muted' >Job</IonLabel>
                    <div className="event-details">
                      <div className=""> <IonIcon aria-hidden="true" icon={briefcaseOutline} /> {api.rosters?.rosterDetail?.job_allocation?.title}</div>
                      {(api.rosters?.rosterDetail?.job_allocation?.site_address) && <div className=""> <IonIcon aria-hidden="true" icon={locationOutline} /> {api.rosters?.rosterDetail?.job_allocation?.site_address}</div>}
                      {(api.rosters?.rosterDetail?.job_allocation?.description) &&
                        <div className='instruction-container mt-2'>
                          <div className='d-flex align-items-center'><IonIcon aria-hidden="true" icon={bookOutline} />&nbsp;<span className='text-muted' style={{ fontSize: 12 }}> Job Description</span> </div>
                          <div dangerouslySetInnerHTML={{ __html: api.rosters?.rosterDetail?.job_allocation?.description }}></div>
                        </div>
                      }
                    </div>
                  </IonCol>
                </IonRow>
              </div>
            </IonAccordion>
            <IonAccordion value="third">
              <IonItem slot="header">
                <IonLabel>Client</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                <IonRow className="job-allo-container">
                  <IonCol className="col-12">
                    <IonLabel className='font-weight-bold text-muted' >Client</IonLabel>
                    <div className="event-details">
                      <div className="client"><IonIcon aria-hidden="true" icon={businessOutline} /> {api.rosters?.rosterDetail?.job_allocation?.client}</div>
                      <div className="client"><IonIcon aria-hidden="true" icon={locationOutline} /> {api.rosters?.rosterDetail?.job_allocation?.address}</div>
                      <div onClick={() => { handleEmailClick(api.rosters?.rosterDetail?.job_allocation?.email) }} className="client"><IonIcon aria-hidden="true" icon={mailOutline} /> {api.rosters?.rosterDetail?.job_allocation?.email}</div>
                      <div onClick={() => { handlePhoneClick(api.rosters?.rosterDetail?.job_allocation?.phone) }} className="client"><IonIcon aria-hidden="true" icon={callOutline} /> {api.rosters?.rosterDetail?.job_allocation?.phone}</div>
                    </div>
                  </IonCol>
                </IonRow>
              </div>
            </IonAccordion>
          </IonAccordionGroup>
          <hr />

          <IonRow className="job-allo-container">
            <IonCol className="col-12">
              <IonLabel className='font-weight-bold text-muted' >Resources</IonLabel>
              <div className="event-details">
                {(api.rosters?.rosterDetail?.job_allocation && api.rosters?.rosterDetail?.job_allocation?.job_allocation_attachments?.length > 0) &&
                  api.rosters?.rosterDetail?.job_allocation?.job_allocation_attachments?.map((resources) => (
                    <div className="pb-1" key={resources.id} onClick={(e) => openFileInBrowser(resources.file_url, resources.id)}> <FontAwesomeIcon icon={(resources.file_type.includes('image')) ? faImage : faFilePdf} /> {resources?.file_name}</div>
                  ))
                }
              </div>
            </IonCol>
          </IonRow>
          <hr />

          {(isSameDayOrFuture() && (!shiftCompleted) && (api.rosters?.rosterDetail?.accepted)) ? (<IonCard >
            <IonCardContent >
              {/* <form onSubmit={(e) =>setIsAlertOpen(true)}> */}
              <IonItem>
                <IonLabel>Start Time</IonLabel>
                <IonDatetimeButton datetime="datetime"></IonDatetimeButton>
                <IonModal keepContentsMounted={true}>
                  <IonDatetime value={startTime} id="datetime" minuteValues={minuteValuesInterval} presentation="time" onIonChange={(e) => setStartTime(e.detail.value!)}></IonDatetime>
                </IonModal>
              </IonItem>
              <IonItem>
                <IonLabel>End Time</IonLabel>
                <IonDatetimeButton datetime="datetime2"></IonDatetimeButton>
                <IonModal keepContentsMounted={true}>
                  <IonDatetime value={endTime} id="datetime2" minuteValues={minuteValuesInterval} presentation="time" onIonChange={(e) => setEndTime(e.detail.value!)}></IonDatetime>
                </IonModal>
              </IonItem>
              {/* <IonItem>
                  <IonLabel>Break</IonLabel>
                  <IonSelect
                    value={selectedMinute}
                    placeholder="Select Minute"
                    onIonChange={handleMinuteChange}
                  >
                    <IonSelectOption value="15">15 minutes</IonSelectOption>
                    <IonSelectOption value="30">30 minutes</IonSelectOption>
                    <IonSelectOption value="45">45 minutes</IonSelectOption>
                    <IonSelectOption value="60">60 minutes</IonSelectOption>
                  </IonSelect>
                </IonItem> */}
              <IonItem className="pt-2 border rounded mt-2">
                <IonList className='w-100'>
                  <IonLabel><FontAwesomeIcon icon={faBowlFood} /> Breaks</IonLabel>
                  <IonGrid>
                    <IonRow>
                      <IonCol size="5" className='ion-text-center'>
                        Start Time
                      </IonCol>
                      <IonCol size="5" className='ion-text-center'>
                        End Time
                      </IonCol >
                      <IonCol size="auto">

                      </IonCol>
                    </IonRow>
                    {breaktimeFields.map((timeField, index) => (
                      <IonRow key={index}>

                        <IonCol size="5">
                          <IonDatetimeButton datetime={"breakdatetime_" + index}></IonDatetimeButton>
                          <IonModal keepContentsMounted={true}>
                            <IonDatetime id={"breakdatetime_" + index} presentation="time" showDefaultButtons minuteValues={minuteValuesInterval} value={(timeField.startTime) ? timeField.startTime : null} onIonChange={(e) => handleChange(index, 'startTime', e.detail.value!)}></IonDatetime>
                          </IonModal>
                        </IonCol>
                        <IonCol size="5">
                          <IonDatetimeButton datetime={"breakdatetime2_" + index}></IonDatetimeButton>
                          <IonModal keepContentsMounted={true}>
                            <IonDatetime id={"breakdatetime2_" + index} presentation="time" showDefaultButtons minuteValues={minuteValuesInterval} value={(timeField.endTime) ? timeField.endTime : null} onIonChange={(e) => handleChange(index, 'endTime', e.detail.value!)}></IonDatetime>
                          </IonModal>
                        </IonCol>
                        <IonCol size='2'>
                          <IonButton size='small' color="light" onClick={() => handleRemoveField(index)}>
                            <IonIcon slot="icon-only" size='small' icon={trash}></IonIcon>
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    ))}
                    <IonButton expand="block" onClick={handleAddField}> + Add Break</IonButton>

                  </IonGrid>
                </IonList>
              </IonItem>
              {api.rosters?.rosterDetail?.job_allocation?.equipment?.length > 0 && (
                <IonItem className="pt-2 border rounded mt-2">
                  <IonList className="w-100">
                    <IonLabel>
                      <FontAwesomeIcon icon={faTruckMonster} /> Equipments
                    </IonLabel>
                    {api.rosters?.rosterDetail?.job_allocation?.equipment?.map((equipment) => (
                      <div key={equipment.id} className="mt-3 mb-3">
                        <IonItem>
                          <IonCheckbox
                            onIonChange={() => handleCheckboxChange(equipment.id)}
                            checked={!!checkedEquipments[equipment.id]}
                          />
                          <IonLabel className="text-capitalize font-weight-bold ml-2">
                            <FontAwesomeIcon icon={faPlane} /> {equipment.title}
                          </IonLabel>
                        </IonItem>
                        <div className={!checkedEquipments[equipment.id] ? 'dimmed' : ''}>
                          <IonItem>
                            <IonLabel>Start Time</IonLabel>
                            <IonDatetimeButton
                              datetime={'datetime' + equipment.id}
                              disabled={!checkedEquipments[equipment.id]}
                            ></IonDatetimeButton>
                            <IonModal keepContentsMounted={true}>
                              <IonDatetime
                                value={startTime}
                                id={'datetime' + equipment.id}
                                minuteValues="0,15,30,45"
                                presentation="time"
                                onIonChange={(e) => handleStartTimeChange(e, equipment.id)}
                                disabled={!checkedEquipments[equipment.id]}
                              ></IonDatetime>
                            </IonModal>
                          </IonItem>
                          <IonItem>
                            <IonLabel>End Time</IonLabel>
                            <IonDatetimeButton
                              datetime={'datetime2' + equipment.id}
                              disabled={!checkedEquipments[equipment.id]}
                            ></IonDatetimeButton>
                            <IonModal keepContentsMounted={true}>
                              <IonDatetime
                                value={endTime}
                                id={'datetime2' + equipment.id}
                                minuteValues="0,15,30,45"
                                presentation="time"
                                onIonChange={(e) => handleEndTimeChange(e, equipment.id)}
                                disabled={!checkedEquipments[equipment.id]}
                              ></IonDatetime>
                            </IonModal>
                          </IonItem>
                          <IonItem>
                            <IonLabel>Break Hours</IonLabel>
                            <IonDatetimeButton
                              datetime={'datetime3' + equipment.id}
                              disabled={!checkedEquipments[equipment.id]}
                            ></IonDatetimeButton>
                            <IonModal keepContentsMounted={true}>
                              <IonDatetime
                                value="00:00"
                                id={'datetime3' + equipment.id}
                                hourValues="0,1,2,3"
                                minuteValues="0,15,30,45"
                                presentation="time"
                                onIonChange={(e) => handleBreakTimeChange(e, equipment.id)}
                                disabled={!checkedEquipments[equipment.id]}
                              ></IonDatetime>
                            </IonModal>
                          </IonItem>
                        </div>
                      </div>
                    ))}
                  </IonList>
                </IonItem>
              )}
              {(api.rosters?.rosterDetail?.job_allocation?.products?.length > 0) && <IonItem className="pt-2 border rounded mt-2">
                <IonList className='w-100'>
                  <IonLabel><FontAwesomeIcon icon={faBoxes} /> Products</IonLabel>
                  <IonGrid >
                    {api.rosters?.rosterDetail?.job_allocation?.products?.map((product, index) => (
                      <IonRow key={index}>
                        <IonCol size='8'><IonItem lines="none"><IonLabel><FontAwesomeIcon icon={faBox} /> {product.name} <br /> ({product.product_type.name})</IonLabel></IonItem></IonCol>
                        <IonCol size='4'>
                          <IonItem lines="none">
                            <div >
                              <IonInput
                                type="number"
                                className='p-1'
                                style={{ border: "1px solid black", borderRadius: 3, height: 50 }}
                                onIonInput={e => handleProductInput(e, product.id)}
                              />
                            </div>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    ))}
                  </IonGrid>

                </IonList>
              </IonItem>}
              <IonItem className="rounded mt-2 mb-2">
                <input
                  type="file"
                  accept=".pdf,image/*"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  required
                />
              </IonItem>
              <IonItem className="pt-2 rounded mt-2">
                <IonTextarea className="custom-textarea" autoGrow={true} placeholder="Type any Comments/Remarks here" onIonInput={(e) => setComment(e.target.value!)}></IonTextarea>
              </IonItem>
              <IonButton className='mt-3' expand="full" shape='round' type="submit" color="light" onClick={() => setIsAlertOpen(true)}>
                Submit
              </IonButton>
              {/* </form> */}
            </IonCardContent>
          </IonCard>) : (compareDate(moment(api.rosters?.rosterDetail?.date).format('Y-MM-DD'), moment(new Date()).format('Y-MM-DD')) && api.rosters?.rosterDetail?.accepted != null) && (
            <IonCard>
              { }
              <IonCardContent className=''>
                {(api.rosters?.rosterDetail?.timesheet_entries.length > 0) && <div className=" text-dark h6 text-center"><FontAwesomeIcon icon={faUserClock} /> Entered Time: {moment(api.rosters?.rosterDetail?.timesheet_entries[0]?.start_time, "HH:mm:ss").format("hh:mm A")} - {moment(api.rosters?.rosterDetail?.timesheet_entries[0]?.end_time, "HH:mm:ss").format("hh:mm A")}</div>}
                {(api.rosters?.rosterDetail?.timesheet_entries.length > 0) && <div className=" text-dark h6 text-center">Total Worked Hours: {calculatePayrunHours(api.rosters?.rosterDetail?.timesheet_entries[0])} Hours</div>}
                {
                  (api.rosters?.rosterDetail?.timesheet_entries.length > 0 && api.rosters?.rosterDetail?.timesheet_entries[0]?.timesheet_entry_equipments.length > 0) &&
                  api.rosters?.rosterDetail?.timesheet_entries[0]?.timesheet_entry_equipments.map((equipmentEntry: any) => (
                    <div className='border p-1'>
                      <IonLabel className='text-dark font-weight-bold'><FontAwesomeIcon icon={faTruckMonster} /> {equipmentEntry.equipment?.title}</IonLabel>
                      <div className=" text-dark text"> Entered Time: {moment(equipmentEntry.start_time, "HH:mm:ss").format("hh:mm A")} - {moment(equipmentEntry.end_time, "HH:mm:ss").format("hh:mm A")}</div>
                      <div className=" text-dark text"> Break: {equipmentEntry.break_hours} minutes</div>
                      <div className=" text-dark text">Total: {calculateEquipmentHours(equipmentEntry)} Hours</div>
                    </div>
                  ))
                }
                <div className={((api.rosters?.rosterDetail?.accepted) ? (api.rosters?.rosterDetail?.completed || shiftCompleted) ? 'text-primary' : 'text-dark' : 'text-danger') + ' font-weight-bold text-center mt-2 mb-2'}><FontAwesomeIcon icon={api.rosters?.rosterDetail?.accepted ? (api.rosters?.rosterDetail?.completed || shiftCompleted) ? faCheckCircle : faTimesCircle : faExclamationCircle} /> {api.rosters?.rosterDetail?.accepted ? (api.rosters?.rosterDetail?.completed || shiftCompleted) ? 'Shift Completed' : 'Not completed' : 'Shift Rejected'}</div>
                {(api.rosters?.rosterDetail?.timesheet_entries.length > 0) && (api.rosters?.rosterDetail?.timesheet_entries[0].supervisor_accepted != null)
                  &&
                  ((api.rosters?.rosterDetail?.timesheet_entries[0].supervisor_accepted) ? (
                    <div className='text-center'>
                      <IonIcon icon={checkmarkCircle} className='text-success' style={{ fontSize: 16 }} /> Docket Entry Accepted
                    </div>
                  ) : (
                    <div className='text-center'>
                      <IonIcon icon={closeCircle} className='text-danger' style={{ fontSize: 16 }} /> <span className='font-weight-bold'>Docket Entry Rejected</span>
                      <div>
                        Reason: {api.rosters?.rosterDetail?.timesheet_entries[0].supervisor_comment}
                      </div>
                    </div>
                  ))
                }
              </IonCardContent>
            </IonCard>
          )
          }
        </IonGrid>
        <IonAlert
          header="Confirmation"
          subHeader="Are you sure you want to submit the timesheet?"
          trigger="submit-alert"
          isOpen={isAlertOpen}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
              },
            },
            {
              text: 'Confirm',
              role: 'confirm',
              handler: (e) => {
                handleSubmit(e);
              },
            },
          ]}
          onDidDismiss={() => setIsAlertOpen(false)}
        ></IonAlert>
        <IonToast
          isOpen={isOpen}
          message="Cannot submit the timesheet. Please fill start and end times!"
          onDidDismiss={() => setIsOpen(false)}
          duration={3000}
          position='top'
          icon={warning}
          color="danger"
        ></IonToast>
        <IonLoading
          isOpen={loading}
          onDidDismiss={() => setLoading(false)}
          message={'Please wait...'}
        />
      </IonContent>
    </IonPage>
  );
};

export default RosterDetail;