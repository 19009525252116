import { useEffect, useState } from "react";
import { Token } from "../interfaces/authInterfaces.ds";
import {
  Approval,
  Roster,
  RosterModule,
} from "../interfaces/rosterinterfaces.ds";

import { Storage } from "@ionic/storage";
import { man } from "ionicons/icons";
import moment from "moment";

const useRosterModule = (
  portalAPI: string,
  store: Storage,
  token: Token | undefined,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  user_id: any
) => {
  //state manifests
  const [rosters, setRosters] = useState<Roster[] | undefined>([]);
  const [resources, setResources] = useState<any>([]);
  const [rosterToday, setRosterToday] = useState<Roster[] | undefined>([]);
  const [filteredRosters, setfilteredRosters] = useState<Roster[] | undefined>(
    []
  );
  const [rosterDetail, setRosterDetail] = useState<Roster | undefined>();
  const [approvalRosters, setApprovalRosterDetail] = useState< Roster[] | undefined>([]);
  const [historyRosters, setHistoryRoster] = useState<Roster[] | undefined>([]);

  //update manifests when tokens change
  useEffect(() => {
    if (token && token.pass && user_id) {
      (async function () {
        await getRosters(user_id);
      })();
    }
  }, []);

  //get all manifests and set state and store locally
  const getRosters = async (user_id: number) => {
    setLoading(true);
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    try {
      if (token) {
        const response = await fetch(`${portalAPI}/rosters/${user_id}.json `, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.stringify({
              bob: currentDate,
              id: token.id,
              token: token.token,
              pass: token.pass,
            }),
          },
        });

        if (!response.ok) {
          throw Error(response.statusText);
        }

        var data = await response.json();

        if (data.status == 200 && data.rosters != null) {
          setRosters(data.rosters);
          setResources(data.resources);
          const today = moment(new Date()).format("YYYY-MM-DD");

          const filteredItems = data.rosters.filter((item: any) => {
            return (
              moment(item.date).format("YYYY-MM-DD") === today && item.accepted
            );
          });

          const filteredDateRosters = data.rosters.filter((item: any) => {
            return (
              moment(item.date).format("YYYY-MM-DD") >=
                moment(new Date()).subtract(2, "days").format("YYYY-MM-DD") &&
              item.accepted
            );
          });

          const approvalFilteredRosters = data.rosters.filter((item: any) => {
            return (
              moment(item.date).format("YYYY-MM-DD") >=
              moment(new Date()).subtract(5, "days").format("YYYY-MM-DD")
            );
          });

          const historyFilteredRosters = data.rosters.filter((item: any) => {
            return (
              moment(item.date).format("YYYY-MM-DD") <
              moment(new Date()).format("YYYY-MM-DD") && item.accepted
            );
          });

          setfilteredRosters(filteredDateRosters);
          setRosterToday(filteredItems);
          setApprovalRosterDetail(approvalFilteredRosters);
          setHistoryRoster(historyFilteredRosters.reverse());

          store.set(`${process.env.REACT_APP_STORE}rosters`, data.rosters);
          return true;
        }

        return false;
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
    return false;
  };

  const getRosterDetail = async (id: number) => {
    setLoading(true);
    let rost: Roster | undefined;
    if (rosters?.length) {
      const temp = rosters.filter((item: any) => {
        return item.id == id;
      });
      setRosterDetail(temp[0]);
      rost = temp[0];
      return rost;
    }

    setLoading(false);

    return rost;
  };

  const doRefresh = async (event: any, user_id: number | undefined) => {
    if (user_id) {
      getRosters(user_id);
    }
    event.detail.complete();
  };

  const submitRoster = async (data: any) => {
    if (!token) return false;
    setLoading(true);
    try {
      //If update
      let url, options;
      url = `${portalAPI}/rosters.json `;
      options = {
        headers: {
          "Content-Type": "application/json",
          'Authorization': JSON.stringify({bob: 'Hi', id: token.id, token: token.token, pass: token.pass})
        },
        method: "POST",
        body: JSON.stringify(data),
      };

      const response = await fetch(url, options);
      
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const resultdata = await response.json();
      if (resultdata?.status === 200) {
        await getRosters(user_id);
      }
      setLoading(false);

      //Send data back so it will show the errors
      return resultdata;
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
    return false;
  };

  const submitApproval = async (tmpApproval: Approval) => {
    if (!token) return false;
    setLoading(true);
    try {
      //If update
      let url, options;
      url = `${portalAPI}/rosters/${tmpApproval.id}.json `;
      options = {
        headers: {
          "Content-Type": "application/json",
          'Authorization': JSON.stringify({bob: 'Hi', id: token.id, token: token.token, pass: token.pass})
        },
        method: "PUT",
        body: JSON.stringify(tmpApproval),
      };

      const response = await fetch(url, options);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const data = await response.json();

      if (data?.status === 200 && data?.roster) {
        await getRosters(tmpApproval.user_id);
      }

      setLoading(false);
      //Send data back so it will show the errors
      return data;
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
    return false;
  };

  const accessFile = async (id: number) => {
    if (!token) return false;
    setLoading(true);

    try {
      //If update
      let dataurl, options;
      dataurl = `${portalAPI}/rosters/fileaccess/${id}.json `;
      options = {
        headers: {
          "Content-Type": "application/json",
          'Authorization': JSON.stringify({bob: 'Hi', id: token.id, token: token.token, pass: token.pass})
        },
        method: "GET",
      };

      const response = await fetch(dataurl, options);

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const data = await response.json();      
      const file = data.file;
      
      if(!file){ 
        return false;
      }
      setLoading(false);
      //Send data back so it will show the errors
      return file;
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
    return false;
  };

  const rosterModule: RosterModule = {
    rosters,
    filteredRosters,
    rosterToday,
    getRosters,
    rosterDetail,
    getRosterDetail,
    approvalRosters,
    historyRosters,
    doRefresh,
    submitApproval,
    resources,
    submitRoster,
    accessFile
  };

  return rosterModule;
};

export default useRosterModule;
