import { IonBackButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonIcon, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToolbar } from '@ionic/react';
import RosterCardComponent from '../components/RosterCardComponent';

import './Rosters.css';

import { Api } from '../services/api';
import { RouteComponentProps } from 'react-router-dom';
import { calendarOutline, sadOutline } from 'ionicons/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCalendarDays, faCalendarXmark, faHistory } from '@fortawesome/free-solid-svg-icons';
import ToolbarComponent from '../components/ToolbarComponent';

interface LoginPageProps extends RouteComponentProps {
  api: Api
}

const RosterHistory: React.FC<LoginPageProps> = ({api}) => {


  return (
    <IonPage>
      <IonHeader >
        <ToolbarComponent icon={null} title='Roster History' backbutton={true} backlink='rosters'/>
      </IonHeader>
      <IonContent fullscreen className="custom-content">
        <IonRefresher slot="fixed" onIonRefresh={(e) => api.rosters.doRefresh(e, api.authentication?.user?.id)}>
            <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {
          (api.rosters?.historyRosters?.length)? 
          (<>
            {api.rosters?.historyRosters?.map((item, index) => (
              <div key={index}>
                <RosterCardComponent id={index} roster={item} />
              </div>
            )
            )}
          </>) 
          : 
          (<>
            <div className="text-center mt-4 text-muted">
              <b>No Rosters Yet!!</b> <IonIcon icon={sadOutline} />
            </div>
          </>)
        }
      </IonContent>
    </IonPage>
  );
};

export default RosterHistory;
