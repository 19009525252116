import React from 'react';
import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './About.css';
import ToolbarComponent from '../components/ToolbarComponent';

const About: React.FC = () => {
  return (
    <IonPage>
        <IonHeader >
            <ToolbarComponent icon={null} title='About' backbutton={true} backlink='settings' />

        </IonHeader>
        <IonContent>
            <div className="about-container">
                <div className="about-content">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed fermentum tortor eget ullamcorper pretium. In ullamcorper ligula et arcu sodales, vitae venenatis nunc posuere. Nullam et mi eu justo gravida ultrices.</p>
                    <p>Nullam lacinia ligula id elit lobortis pellentesque. Suspendisse at rutrum velit. Vestibulum mollis risus leo, vel ultrices sem efficitur vitae. Sed sed erat eget justo consectetur rhoncus. In et eros at mauris elementum semper.</p>
                    <p>Donec nec lacus nec justo auctor fermentum. Suspendisse potenti. Sed accumsan iaculis aliquet. Etiam in nibh lectus.</p>
                </div>
            </div>
        </IonContent>
    </IonPage>
  );
};

export default About;