import React, { useState } from 'react';
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonInput,
  IonButtons,
  IonPage,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonDatetime,
  IonDatetimeButton,
  IonAlert,
  IonIcon,
  IonTextarea,
  IonItemDivider,
  IonToast,
  IonLoading
} from '@ionic/react';

import { Api } from '../services/api';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { checkmarkCircleOutline, closeCircleOutline, warning } from 'ionicons/icons';

interface ModalContentProps {
    api: Api;
    onDismiss: () => void;
  }
const RosterAddComponent: React.FC<ModalContentProps>= ({api, onDismiss}) => {
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [description, setDescription] = useState<string | any>();
  const [siteAddress, setSiteAddress] = useState<string | any>();
  const [selectedUser, setSelectedUser] = useState<string | any>((api.rosters?.resources?.users?.length == 1 ? api.rosters?.resources?.users[0] : null));
  const [selectedJobTasks, setSelectedJobTasks] = useState<string | any>();
  const [selectedEquipment, setSelectedEquipment] = useState<any>();
  const [selectedProducts, setSelectedProducts] = useState<any>();
  const [selectedSupervisor, setSelectedSupervisor] = useState<any>();
  const [jobAllocationTitle, setJobAllocationTitle] = useState<string | any>('JA'+ Math.random().toString(36).toUpperCase().substr(2, 8));
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [noErrors, setNoErrors] = useState(false);
  const [selectableSupervisors, setSelectableSupervisors] = useState((api.authentication?.user && api.authentication?.user?.group_id <= 2 )? api.rosters?.resources?.supervisors : []);
  const [newJobAllocation, setNewJobAllocation] = useState('');
  const [startTime, setStartTime] = useState<string | any>(getCurrentTime());
  const [endTime, setEndTime] = useState<string | any>(getCurrentTime());
  const [date, setDate] = useState<string | any>(getCurrentDate());
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  function getCurrentTime() {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    return `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}`;
  }

  function getCurrentDate() {
    const now = new Date();
    return `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}T${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}`;
  }

  const handleSelectionChangeProject = (event: CustomEvent) => {
    const selectedIndex = event.detail.value;
    console.log("project",selectedIndex);
    if(selectedIndex.client_id){
      const client = api.rosters?.resources?.clients?.filter((client : any, index: number) => {
        return selectedIndex.client_id == client.id
      });
      setSelectedClient(client[0]);
      if(api.authentication?.user && api.authentication?.user?.group_id > 2 && client[0].supervisors.length > 0){
        setSelectableSupervisors(client[0].supervisors);
      }else{
        setSelectableSupervisors((api.authentication?.user && api.authentication?.user?.group_id <= 2 )? api.rosters?.resources?.supervisors : []);
      }
    }else{
      setSelectedClient(null);
    }
    setSelectedProject(selectedIndex);
  };

  const handleSelectionChangeClient= (event: CustomEvent) => {
    const selectedIndex = event.detail.value;
    console.log("client",selectedIndex);
    setSelectedClient(selectedIndex);
    if(api.authentication?.user && api.authentication?.user?.group_id >= 2 && selectedIndex.supervisors.length > 0){
      setSelectableSupervisors(selectedIndex.supervisors);
    }else{
      setSelectableSupervisors((api.authentication?.user && api.authentication?.user?.group_id <= 2 )? api.rosters?.resources?.supervisors : []);
    }
  };

  const handleSelectAddress = async (selectedAddress : any) => {
    const results = await geocodeByAddress(selectedAddress);
    const latLng = await getLatLng(results[0]);
    setSiteAddress(selectedAddress);
  };

  const handleSelectionChangeUser= (event: CustomEvent) => {
    const selectedIndex = event.detail.value;
    console.log("user",selectedIndex);
    setSelectedUser(selectedIndex);
  };

  const handleSelectionChangeJobTask = (event: CustomEvent) => {
    const selectedIndex = event.detail.value;
    console.log("job tasks",selectedIndex);
    setSelectedJobTasks(selectedIndex);
  };

  const handleSelectionChangeEquipment = (event: CustomEvent) => {
    const selectedIndex = event.detail.value;
    console.log("equipment",selectedIndex);
    setSelectedEquipment(selectedIndex);
  };

  const handleSelectionChangeProduct = (event: CustomEvent) => {
    const selectedIndex = event.detail.value;
    console.log("product",selectedIndex);
    setSelectedProducts(selectedIndex);
  };

  const handleSelectionChangeSupervisor = (event: CustomEvent) => {
    const selectedIndex = event.detail.value;
    console.log("supervisor",selectedIndex);
    setSelectedSupervisor(selectedIndex);
  };

  const handleEmailClick = (email: any) => {
    window.location.href = `mailto:${email}`;
  };

  const handlePhoneClick = (phone: any) => {
    window.location.href = `tel:${phone}`;
  };

  const openFileInBrowser = (url: string) => {
    const uri = api.portalAPI?.replace('/api', '') + url;
    window.open(uri, '_blank');
  };

  const clearInput = () => {
    setSiteAddress('');
  };

  const submit = async () => {
    setLoading(true);
    const data : any = {
      project_id : selectedProject?.id || null,
      client_id : selectedClient?.id || null,
      title : jobAllocationTitle || null,
      description : description || null,
      site_address: siteAddress || null,
      user_id : selectedUser?.id || null,
      job_tasks : selectedJobTasks || null,
      equipment: selectedEquipment || null,
      products: selectedProducts || null,
      supervisor_id: selectedSupervisor?.id || null,
      roster : {
        date : date || null,
        start_time: startTime || null,
        end_time : endTime || null
      }
    }
    const keysToExclude = ['project_id', 'client_id', 'equipment', 'products', 'description'];
    if(api.authentication?.user && api.authentication?.user?.group_id <= 4){
      keysToExclude.push('supervisor_id');
    }
    console.log(keysToExclude);
    const isDataValid = Object.keys(data).every((key) => {
    // Check if the key is not in the keysToExclude array
      if (!keysToExclude.includes(key)) {
        const value = data[key];

        // Check if the value is not undefined, null, or an empty string
        return value !== undefined && value !== null && value !== '';
      }

      // Skip the check for excluded keys
      return true;
    });

    if(isDataValid){
      const response = await api.rosters.submitRoster(data);
      if(!response){
        setMessage('Error Submiting the roster. Please fill all the fields.');
        setNoErrors(false);
        setIsOpen(true);
      }else{
        setMessage('Roster has been successfully added');
        setNoErrors(true);
        setIsOpen(true);
        onDismiss();
      }
    }else{
      setMessage('Error Submiting the roster. Please fill all the fields.');
      setNoErrors(false);
      setIsOpen(true);
    }
    setLoading(false);
    
  }

  return (
    <IonPage >
      <IonHeader>
        <IonToolbar>
         <IonButtons slot="start">
            <IonButton color="medium" onClick={() => onDismiss()}>
              Cancel
            </IonButton>
          </IonButtons>
          <IonTitle>Add Job Allocation</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className='p-1'>
            <IonItem>
                <IonLabel>Project</IonLabel>
                <IonSelect
                  value={selectedProject ? selectedProject : ''}
                  placeholder="Select Project"
                  onIonChange={handleSelectionChangeProject}
                >
                  {api.rosters?.resources?.projects?.map((project : any, index : any) => (
                    <IonSelectOption key={index} value={project}>
                      {project.title}
                    </IonSelectOption>
                  ))}
                 </IonSelect>
                 
            </IonItem>
            <IonItem>
                <IonLabel>Client&nbsp;<span className='text-danger' style={{fontSize: 11}}>(Required)</span></IonLabel>
                <IonSelect
                  value={selectedClient ? selectedClient : ''}
                  placeholder="Select Client"
                  onIonChange={handleSelectionChangeClient}
                >
                  {api.rosters?.resources?.clients?.map((client : any, index : any) => (
                    <IonSelectOption key={index} value={client}>
                      {client.company_name}
                    </IonSelectOption>
                  ))}
                 </IonSelect>
                 
            </IonItem>
            <IonItem className="rounded mt-2 mb-2">
              <IonInput label="Job Allocation No. (Required)" labelPlacement="floating" clearInput={true}  placeholder="Type Job Allocation No. here" value={jobAllocationTitle} onIonInput={(e) => setJobAllocationTitle(e.target.value!)}></IonInput>
            </IonItem>
            <IonItem className="rounded mt-2 mb-2">
              <IonTextarea className="custom-textarea" autoGrow={true} placeholder="Type Descriptions here" onIonInput={(e) => setDescription(e.target.value!)}></IonTextarea>
            </IonItem>
            <IonItem className="rounded pb-2">
              <div className='pb-2 w-100'>
                <PlacesAutocomplete
                  value={siteAddress}
                  onChange={setSiteAddress}
                  onSelect={handleSelectAddress}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className='w-100'>
                      <div className='d-flex align-items-center'>
                        <input
                          {...getInputProps({
                            placeholder: 'Type site address (Required)...',
                            className: 'location-search-input form-control',
                          })}
                        />
                        {siteAddress && (
                          <IonIcon
                            icon={closeCircleOutline}
                            onClick={clearInput}
                            size="large"
                          />
                        )}
                      </div>
                     
                      <div className="autocomplete-dropdown-container bg-light">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                            fontSize: 14,
                            fontWeight: 'bold',
                            margin: 2,
                            padding: 2,
                            borderRadius : 5
                          };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
              
            </IonItem>
            <IonItem>
                <IonLabel className='d-flex align-items-center'>User&nbsp;<span className='text-danger' style={{fontSize: 11}}>(Required)</span></IonLabel>
                <IonSelect
                  value={selectedUser ? selectedUser : ''}
                  placeholder="Select User"
                  onIonChange={handleSelectionChangeUser}
                >
                  {api.rosters?.resources?.users?.map((user : any, index : any) => (
                    <IonSelectOption key={index} value={user}>
                      {user.name}
                    </IonSelectOption>
                  ))}
                 </IonSelect>
                 
            </IonItem>
            <IonItem>
                <IonLabel className='d-flex align-items-center'>Job Task&nbsp;<span className='text-danger' style={{fontSize: 11}}>(Required)</span></IonLabel>
                <IonSelect
                  value={selectedJobTasks ? selectedJobTasks : ''}
                  placeholder="Select Job Task"
                  onIonChange={handleSelectionChangeJobTask}
                >
                  {api.rosters?.resources?.job_tasks?.map((job : any, index : any) => (
                    <IonSelectOption key={index} value={job.id}>
                      {job.job_title}
                    </IonSelectOption>
                  ))}
                 </IonSelect>
                 
            </IonItem>
            <IonItem>
                <IonLabel>Equipment</IonLabel>
                <IonSelect
                  value={selectedEquipment ? selectedEquipment : ''}
                  placeholder="Select Equipment"
                  onIonChange={handleSelectionChangeEquipment}
                  multiple={true}
                >
                  {api.rosters?.resources?.equipment?.map((equipment : any, index : any) => (
                    <IonSelectOption key={index} value={equipment.id}>
                      {equipment.title}
                    </IonSelectOption>
                  ))}
                 </IonSelect>
                 
            </IonItem>
            <IonItem>
                <IonLabel>Products</IonLabel>
                <IonSelect
                  value={selectedProducts ? selectedProducts : ''}
                  placeholder="Select Products"
                  onIonChange={handleSelectionChangeProduct}
                  multiple={true}
                >
                  {api.rosters?.resources?.products?.map((product : any, index : any) => (
                    <IonSelectOption key={index} value={product.id}>
                      {product.name}
                    </IonSelectOption>
                  ))}
                 </IonSelect>
                 
            </IonItem>
            <IonItem>
                <IonLabel className='d-flex align-items-center'>Supervisors&nbsp; {(api.authentication?.user && api.authentication?.user?.group_id > 3)? (<span className='text-danger' style={{fontSize: 11}}>(Required)</span>) : null}</IonLabel>
                {(selectableSupervisors.length > 0 ) ? <IonSelect
                  value={selectedSupervisor ? selectedSupervisor : ''}
                  placeholder="Select Supervisor"
                  onIonChange={handleSelectionChangeSupervisor}
                >
                  { selectableSupervisors?.map((supervisor : any, index : any) => (
                    <IonSelectOption key={index} value={supervisor}>
                      {supervisor.name}
                    </IonSelectOption>
                  )) }
                 </IonSelect> : <IonLabel className='text-danger'  slot='end' style={{fontSize: 13}}>Select the Client</IonLabel>}
                 
            </IonItem>
            <IonItemDivider>
              <IonLabel>Enter the Roster</IonLabel>
            </IonItemDivider>
            <div>
              {/* {selectedJobAllocation && false && (
                <div className='m-2 p-2 card'>
                  <div className='font-weight-bold text-muted mb-1'>
                    Allocation Detail
                  </div>
                  <IonRow className="job-allo-container">
                    <IonCol className="col-12">
                      <IonLabel className='font-weight-bold text-muted mb-1' >Supervisor: </IonLabel>
                      <IonLabel className='font-weight-bold' >{ (selectedJobAllocation?.supervisor?.name)? (<><FontAwesomeIcon icon={faUserNinja}  />  {selectedJobAllocation?.supervisor?.name}</>):(<IonLabel> None</IonLabel>)}</IonLabel>
                      
                      <div className="event-details">
                          <div className=""> <IonIcon aria-hidden="true" icon={briefcaseOutline} /> {selectedJobAllocation?.title}</div>
                          {(selectedJobAllocation?.site_address) && <div className=""> <IonIcon aria-hidden="true" icon={locationOutline} /> {selectedJobAllocation?.site_address}</div>}
                          { (selectedJobAllocation?.description) &&
                            <div className='instruction-container mt-2'>
                              <div className='d-flex align-items-center'><IonIcon aria-hidden="true" icon={bookOutline} />&nbsp;<span className='text-muted' style={{fontSize: 12}}> Job Description</span> </div>
                              <div dangerouslySetInnerHTML={{ __html: selectedJobAllocation?.description }}></div>                  
                            </div>
                            }
                      </div>
                    </IonCol>
                  </IonRow>
                  <hr/>
                  <IonAccordionGroup>
                  <IonAccordion value="first">
                      <IonItem slot="header">
                        <IonLabel>Job Task</IonLabel>
                      </IonItem>
                      <div className="ion-padding" slot="content">
                        <IonRow className="job-allo-container">
                          <IonCol className="col-12">
                            {
                              (selectedJobAllocation?.job_tasks?.length > 0) ?
                              selectedJobAllocation?.job_tasks?.map((jobtask: JobTask, index: number) => (
                                <>
                                  <IonLabel className='font-weight-bold text-muted' >Task</IonLabel>
                                  <div className="event-details" key={index}>
                                    <div className=""> <FontAwesomeIcon icon={faHatHard} /> {jobtask?.job_title}</div>
                                    {jobtask?.job_description && (
                                      <div className='instruction-container mt-2'>
                                        <div className='d-flex align-items-center'><IonIcon aria-hidden="true" icon={bookOutline} />&nbsp; <span className='text-muted' style={{fontSize: 12}}>Task Description</span></div>
                                        <div dangerouslySetInnerHTML={{ __html: jobtask?.job_description }}></div>
                                      </div>
                                    )}              
                                  </div>
                                </>
                                )) : (
                                  <div>
                                    None
                                  </div>
                                )
                            }
                          </IonCol>
                        </IonRow>
                      </div>
                    </IonAccordion>
                    <IonAccordion value="second">
                      <IonItem slot="header">
                        <IonLabel>Client</IonLabel>
                      </IonItem>
                      <div className="ion-padding" slot="content">
                        <IonRow className="job-allo-container">
                          <IonCol className="col-12">
                            <IonLabel className='font-weight-bold text-muted' >Client</IonLabel>
                            <div className="event-details">
                              <div className="client"><IonIcon aria-hidden="true" icon={businessOutline} /> {selectedJobAllocation?.client}</div>
                              <div className="client"><IonIcon aria-hidden="true" icon={locationOutline} /> {selectedJobAllocation?.address}</div>
                              <div onClick={()=> {handleEmailClick(selectedJobAllocation?.email)}}  className="client"><IonIcon aria-hidden="true" icon={mailOutline} /> {selectedJobAllocation?.email}</div>
                              <div onClick={() => {handlePhoneClick(selectedJobAllocation?.phone)}} className="client"><IonIcon aria-hidden="true" icon={callOutline} /> {selectedJobAllocation?.phone}</div>
                            </div>
                          </IonCol>
                        </IonRow>
                      </div>
                    </IonAccordion>
                  </IonAccordionGroup>
                  <hr/>
                  <IonRow className="job-allo-container">
                    <IonCol className="col-12">
                      <IonLabel className='font-weight-bold text-muted' >Resources</IonLabel>
                      <div className="event-details">
                        {(selectedJobAllocation?.job_allocation_attachments?.length >  0) ?
                            selectedJobAllocation?.job_allocation_attachments?.map((resources: any) => (
                              <div className="pb-1" key={resources.id} onClick={(e) => openFileInBrowser(resources.file_url)}> <FontAwesomeIcon icon={(resources.file_type.includes('image'))? faImage: faFilePdf} /> {resources?.file_name}</div>
                            )): (
                              <div>None</div>
                            )
                        }              
                      </div>
                    </IonCol>
                  </IonRow>
                  <hr/>
                </div>
              )} */}
            </div>
            <IonItem>
                <IonLabel>Date</IonLabel>
                <IonDatetimeButton datetime={"date"} />
                <IonModal keepContentsMounted={true}>
                  <IonDatetime 
                    id={"date"} 
                    presentation="date"
                    min={ `${new Date().getFullYear()}` } 
                    max={ `${new Date().getFullYear() + 1}`  } 
                    onIonChange={(e) => setDate(e.detail.value!)}
                    showDefaultButtons={true} />
                </IonModal>
            </IonItem>
            <IonItem>
                <IonLabel>Start Time</IonLabel>
                <IonDatetimeButton datetime="starttime"></IonDatetimeButton>
                <IonModal keepContentsMounted={true}>
                    <IonDatetime value={startTime}  id="starttime"  minuteValues="0,15,30,45"  presentation="time" onIonChange={(e) => setStartTime(e.detail.value!)}></IonDatetime>
                </IonModal>
            </IonItem>
            <IonItem>
                <IonLabel>End Time</IonLabel>
                <IonDatetimeButton datetime="endtime"></IonDatetimeButton>
                <IonModal keepContentsMounted={true}>
                    <IonDatetime value={endTime}  id="endtime"  minuteValues="0,15,30,45"  presentation="time" onIonChange={(e) => setEndTime(e.detail.value!)}></IonDatetime>
                </IonModal>
            </IonItem>
            <IonButton className='mt-3' expand="full" shape='round' type="submit" color="light" onClick={()=>setIsAlertOpen(true)}>
                Submit
            </IonButton>
        </div>
        
        
        <IonAlert
          header="Confirmation"
          subHeader="Are you sure you want to submit the timesheet?"
          trigger="submit-alert"
          isOpen={isAlertOpen}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
              },
            },
            {
              text: 'Confirm',
              role: 'confirm',
              handler: (e) => {
                submit();
              },
            },
          ]}
          onDidDismiss={() => setIsAlertOpen(false)}
        ></IonAlert>
        <IonToast
          isOpen={isOpen}
          message={message}
          onDidDismiss={() => setIsOpen(false)}
          duration={3000}
          position= 'top'
          icon={(noErrors) ? checkmarkCircleOutline : warning}
          color="danger"
        ></IonToast>
        <IonLoading
          isOpen={loading}
          onDidDismiss={() => setLoading(false)}
          message={'Please wait...'}
        />
      </IonContent>
    </IonPage>
  );
};

export default RosterAddComponent;