import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonBackButton, IonButtons, IonTitle, IonToolbar } from "@ionic/react";

  
  
  interface ContainerProps {
    icon: any,
    title: string,
    backbutton : boolean,
    backlink : string,
  }
  
  const ToolbarComponent: React.FC<ContainerProps> = ({
    icon,
    title,
    backbutton,
    backlink
  }) => {
    const link = "/" + backlink;
    return (
        <IonToolbar color="custom-toolbar" style={{backgroundColor : '#3f8dcc'}}>
            { backbutton && <IonButtons slot="start">
                <IonBackButton defaultHref={link} />
            </IonButtons>}
          <IonTitle>{icon && <FontAwesomeIcon icon={icon} />} {title}</IonTitle>
        </IonToolbar>
    )
  };
  
  export default ToolbarComponent;
  