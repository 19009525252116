import { useEffect, useState } from "react";
import { Token } from "../interfaces/authInterfaces.ds";

import { Storage } from '@ionic/storage';
import { man } from "ionicons/icons";
import moment from "moment";
import { TimesheetEntry, TimesheetEntryModule } from "../interfaces/timesheetentryinterfaces.ds";


const useTimesheetEntryModule = (portalAPI: string, store: Storage, token: Token | undefined, setLoading: React.Dispatch<React.SetStateAction<boolean>>, user_id: any, roster: any) => {



    const addTimesheetEntry = async (tmpEntry: TimesheetEntry | any) => {
        
        if(!token) return false;
        setLoading(true);

        let formData = new FormData();
        formData.append("file_upload", tmpEntry.file_upload)
        formData.append('data',JSON.stringify(tmpEntry));
        try {
            //If update
            let url, options;
            url = `${portalAPI}/timesheet-entries.json`;
            options = {
                headers: {
                    // 'Content-Type': 'application/json',
                    'Authorization': JSON.stringify({bob: 'Hi', id: token.id, token: token.token, pass: token.pass})
                },
                method: 'POST',
                body: formData
            };
        
  
          const response = await fetch(url,options);
          
          if (!response.ok) { 
            throw Error(response.statusText);
          }
  
          const data = await response.json();

          if(data?.status === 200 && data?.roster){
            await roster.getRosters(user_id);
            return true;
          }
  
          setLoading(false);
          //Send data back so it will show the errors
          return data;
  
        } catch (error) {
          console.log(error);
        }
      
        setLoading(false);
        return false;
    };


    const timesheetModule: TimesheetEntryModule = {
        addTimesheetEntry
    }

    return timesheetModule; 
}


export default useTimesheetEntryModule; 
