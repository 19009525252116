import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  IonIcon,
  IonButton,
  IonAlert,
  IonLoading
} from "@ionic/react";
import "./RosterCardApprovalComponent.css";
import { useHistory } from 'react-router-dom';
import { Roster } from "../interfaces/rosterinterfaces.ds";
import moment from "moment";
import { businessOutline, checkmarkCircle, hammerOutline, locationOutline, timeOutline, closeCircle, close } from "ionicons/icons";
import { Api } from "../services/api";
import { useState } from "react";


interface ContainerProps {
  id: number;
  roster: Roster,
  api: Api
}

const RosterCardApprovalComponent: React.FC<ContainerProps> = ({
  id,
  roster,
  api
}) => {
    const history = useHistory();
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);


    const handleAccept = async () => {
      setLoading(true);
      let data = {
        user_id: roster?.user_id,
        id: roster.id,
        approval: true
      }
      await api.rosters.submitApproval(data);
      setLoading(false);
    };
  
    const handleDeny = async () => {
      setLoading(true);
      let data = {
        user_id: roster?.user_id,
        id: roster.id,
        approval: false
      }
      await api.rosters.submitApproval(data);
      setLoading(false);
    };

    const cardColor = (date: any) => {
      if(roster.accepted != null){
        if(roster.accepted){
          return "background-accepted"
        }else if (!roster.accepted ){
          return "background-denied"
        }
      }else if((moment(date).add(api.timeEntryLimit, 'days')).format('Y-MM-DD') < moment(new Date()).format('Y-MM-DD') ) { 
        return "background-normal"
      }else{
        return "background-normal";
      }
    }

    const handleTimeCardClick = (RosterId: number) => {
      
        // Navigate to the time card detail page for the clicked time card
        // You can use a routing library like react-router-dom for navigation
        history.push(`/rosters/${RosterId}`);
    };

    
    return (
        <IonCard>
            <IonCardContent  className={cardColor(roster.date)} >
              <IonGrid className={(moment(roster.date).format("DD MMM YYYY") === moment(new Date()).format("DD MMM YYYY"))? 'border-right-5 approval-container' : 'approval-container'} >
                <IonRow>
                  <IonCol className="col-2" onClick={() => handleTimeCardClick(roster.id)}>
                    <div className="date">
                      <div className="day">{moment(roster.date).format("D")}</div>
                      <div className="month text-bold">{moment(roster.date).format("MMM")}</div>
                    </div>
                  </IonCol>
                  <IonCol className={(roster.accepted == null)? 'col-7' : 'col-10'} onClick={() => handleTimeCardClick(roster.id)}>
                    <div className="event-details">
                      <div className="time"><IonIcon aria-hidden="true" icon={timeOutline} /> {moment(roster.start_time, "HH:mm:ss").format("hh:mm A")} - {moment(roster.end_time, "HH:mm:ss").format("hh:mm A")}</div>
                      {(roster.job_allocation?.site_address) && <div className="location"> <IonIcon aria-hidden="true" icon={locationOutline} /> {roster.job_allocation?.site_address}</div>}
                      <div className="client"><IonIcon aria-hidden="true" icon={businessOutline} /> {roster.job_allocation?.client}</div>
                      <div className="client"><IonIcon aria-hidden="true" icon={hammerOutline} /> {roster.job_task?.job_title}</div>
                    </div>
                  </IonCol>
                    {(roster.accepted == null && moment(roster?.date).add(api.timeEntryLimit, 'days').format('Y-MM-DD') >= moment(new Date()).format('Y-MM-DD') ) && (
                      <IonCol className="col-3">
                        <div className="event-details">
                          <div>
                            {/* Accept button */}
                            <IonButton
                              fill="clear"
                              size="large"
                              shape="round"
                              color="success"
                              onClick={handleAccept}
                            >
                              <IonIcon slot="icon-only" icon={checkmarkCircle}></IonIcon>
                            </IonButton>

                            {/* Deny button */}
                            <IonButton
                              fill="clear"
                              size="large"
                              shape="round"
                              color="danger"
                              onClick={()=>setIsAlertOpen(true)}
                            >
                              <IonIcon slot="icon-only" icon={closeCircle}></IonIcon>
                            </IonButton>
                          </div>
                        </div>
                      </IonCol>
                    )
                  }
                </IonRow>
                <IonAlert
                  header="Confirmation"
                  subHeader="Are you sure you want to reject?"
                  trigger="submit-alert"
                  isOpen={isAlertOpen}
                  buttons={[
                    {
                      text: 'Cancel',
                      role: 'cancel',
                      handler: () => {
                      },
                    },
                    {
                      text: 'Confirm',
                      role: 'confirm',
                      handler: (e) => {
                        handleDeny();
                      },
                    },
                  ]}
                  onDidDismiss={() => setIsAlertOpen(false)}
                ></IonAlert>
              </IonGrid>
            </IonCardContent>
            <IonLoading
              isOpen={loading}
              onDidDismiss={() => setLoading(false)}
              message={'Please wait...'}
            />
        </IonCard>
    );
};

export default RosterCardApprovalComponent;
