import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
  useIonAlert
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { calendarOutline, hourglassOutline, settingsOutline, timeOutline } from 'ionicons/icons';
import Timesheet from './pages/Timesheet';
import Rosters from './pages/Rosters';
import Settings from './pages/Settings';
import Login from './pages/Login';
import About from './pages/About';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import RosterDetail from './pages/RosterDetail';
import useServerConnection from './services/api';
import RostersApproval from './pages/RostersApproval';
import RosterHistory from './pages/RostersHistory';
import PortalAPIPage from './pages/PortalAPI';
import { useEffect } from 'react';

setupIonicReact();

const App: React.FC = () => {
  const api = useServerConnection();
  const [presentUpdate] = useIonAlert();

  useEffect(() => {
    window.addEventListener('hasUpdate', e =>
      presentUpdate({
        header: 'Update available',
        message: 'Please select update to get the latest version',
        buttons: ['Cancel', { text: 'Update', handler: (d: any) => window.location.reload() }],
        onDidDismiss: (e: any) => { },
      })
    );
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        {api.authentication.isAuthed? (
          <IonTabs>
            <IonRouterOutlet>
              <Route exact path="/timesheet" render={(props) => <Timesheet  {...props} api={api} />}>
              </Route>
              <Route exact path="/approval" render={(props) => <RostersApproval {...props} api={api} />} >
              </Route>
              <Route exact path="/history" render={(props) => <RosterHistory {...props} api={api} />}>
              </Route>
              <Route exact path="/rosters/:id" render={(props) => <RosterDetail {...props} api={api} />} />
              <Route exact path="/rosters" render={(props) =><Rosters {...props} api={api} />} >
              </Route>
              <Route exact path="/settings" render={(props) => <Settings {...props} api={api} />}>
              </Route>
              <Route exact path="/about">
                <About />
              </Route>

              <Route exact path="/">
                <Redirect to="/timesheet" />
              </Route>
            </IonRouterOutlet>

            <IonTabBar slot="bottom">
              <IonTabButton tab="timesheet" href="/timesheet">
                <IonIcon aria-hidden="true" icon={timeOutline} size="large" />
              </IonTabButton>
              <IonTabButton tab="rosters" href="/rosters">
                <IonIcon aria-hidden="true" icon={calendarOutline} size="large" />
              </IonTabButton>
              <IonTabButton tab="approval" href="/approval">
                <IonIcon aria-hidden="true" icon={hourglassOutline} size="large" />

              </IonTabButton>
              <IonTabButton tab="settings" href="/settings">
                <IonIcon aria-hidden="true" icon={settingsOutline} size="large" />
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        ) : (
          <IonRouterOutlet>
            <Route exact path="/timesheet" render={(props) => (!api.isportalAuth)? <PortalAPIPage  {...props} api={api}/> : <Login  {...props} api={api} />}>
            </Route>
            <Route exact path="/approval" render={(props) => (!api.isportalAuth)? <PortalAPIPage  {...props} api={api}/> : <Login {...props} api={api} />} >
            </Route>
            <Route exact path="/history" render={(props) =>(!api.isportalAuth)? <PortalAPIPage  {...props} api={api}/> : <Login {...props} api={api} />}>
            </Route>
            <Route exact path="/rosters/:id" render={(props) =>(!api.isportalAuth)? <PortalAPIPage  {...props} api={api}/> : <Login {...props} api={api} />} />
            <Route exact path="/rosters" render={(props) =>(!api.isportalAuth)? <PortalAPIPage  {...props} api={api}/> :<Login {...props} api={api} />} >
            </Route>
            <Route exact path="/settings" render={(props) =>(!api.isportalAuth)? <PortalAPIPage  {...props} api={api}/> : <Login {...props} api={api} />}>
            </Route>
            <Route exact path="/login" render={(props) =>(!api.isportalAuth)? <PortalAPIPage  {...props} api={api}/> : <Login {...props} api={api} />} />
            <Route exact path="/portal" render={(props) => <PortalAPIPage {...props} api={api} />} />
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/">
              <Redirect to="/timesheet" />
            </Route>
          </IonRouterOutlet>
        )
        }
      </IonReactRouter>
    </IonApp>
  );
}

export default App;
