import { useEffect, useState } from "react";
import { Token, User, AuthenticationModule } from "../interfaces/authInterfaces.ds";
import { Storage } from '@ionic/storage';
import { useHistory } from "react-router-dom";

const useAuthenticationModule = (portalAPI : string, store: Storage, setLoading: React.Dispatch<React.SetStateAction<boolean>>, online: boolean, token: Token | undefined, setToken: React.Dispatch<React.SetStateAction<Token | undefined>>, setTimeEntryLimit: React.Dispatch<React.SetStateAction<number>>) => {
    const history = useHistory();
    const [isAuthed, setIsAuthed] = useState<boolean>(false);
    const [user, setUser] = useState<User | undefined>(undefined); 

    //load authentication data from storage
    useEffect(() => {
        (async function getStorage() {
            console.log('Load authentication from local storage');

            const tmpToken:Token = await store.get(`${process.env.REACT_APP_STORE}token`);
            setToken(tmpToken);

            const tmpUser:User = await store.get(`${process.env.REACT_APP_STORE}user`);
            setUser(tmpUser);
        })();
    }, []);


    useEffect(() => {
        if(!online) return;
        console.log('Authentication Mod: token set check auth');
        if(token && token.pass && !isAuthed){
            //Try authencate with token, 
            (async function inlineAsync() {
                const result = await checkAuthed(token);
                if(result){
                    setIsAuthed(true);
                }else{
                    //store.set(`${STORE}token`,undefined);
                    //setToken(undefined);
                }
            })();
        }
      }, [token, isAuthed, online]);

    const checkAuthed = async (tmpToken: Token) => {
        try {
            const response = await fetch(`${portalAPI}/auth.json`,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': JSON.stringify({bob: 'Hi', id: tmpToken.id, token: tmpToken.token, pass: tmpToken.pass})
            }
            });
            
            if (!response.ok) { 
              throw Error(response.statusText);
            }
            var data = await response.json();
            if(data?.status === 200){
              if(data.limit){
                setTimeEntryLimit(data.limit);
              }
              setIsAuthed(true);
              console.log("checkAuthed",'Success');
            return true;
            }
  
        } catch (error) {
            console.log(error);
        }
        
        console.log("checkAuthed",'Failed');
        return false;
      };


      const sendCode = async (code:string) => {
        console.log('sendCode:', code );
        setLoading(true);
        try {
          const response = await fetch(`${portalAPI}/auth.json`,{
            method: 'POST',
            body: JSON.stringify(
              {
                code: code,
                token: token
              }
              ),
            headers: {
              'Content-Type': 'application/json'
            },
          });
          if (!response.ok) {
            throw Error(response.statusText);
          }
    
          var data = await response.json();
          if(data?.status === 200 && data?.token?.pass && data?.user){
            setIsAuthed(true);
            if(data.limit){
              setTimeEntryLimit(data.limit);
            }
            //set user in state and local
            setUser(data.user);
            store.set(`${process.env.REACT_APP_STORE}user`,data.user);

            //set token ion state and local
            setToken(data.token);
            store.set(`${process.env.REACT_APP_STORE}token`,data.token);
            setLoading(false);
            return true;
          }
    
        } catch (error) {
          console.log(error);
        }
    
        setLoading(false);
        return false;
    };

    const sendEmail = async (email: string) => {
        console.log('sendEmail:', email ,portalAPI);
        if(email.length === 0) return false;
        
        setLoading(true);
        
        try {
          const response = await fetch(`${portalAPI}/auth.json`,{
            method: 'POST',
            body: JSON.stringify({email: email}),
            headers: {
              'Content-Type': 'application/json'
            },
          });
          if (!response.ok) {
            throw Error(response.statusText);
          }
          var data = await response.json();

          if(data?.status === 200 && data.token){
            
            setToken(data.token);
            await store.set(`${process.env.REACT_APP_STORE}email_token`,data.token);
            setLoading(false);
            return true;
          }
        } catch (error) {
          console.log(error); 
        }
    
        setLoading(false);
        return false;
    };

    const cancelEmail = () => {
        setToken(undefined);
        store.set(`${process.env.REACT_APP_STORE}email_token`,undefined);
    };

    const logOff = async () => {
        await store.remove(`${process.env.REACT_APP_STORE}token`);
        await store.remove(`${process.env.REACT_APP_STORE}email_token`);
        window.location.reload();
    }

    const authneticationModule: AuthenticationModule = {
        isAuthed,
        logOff,
        sendCode,
        sendEmail,
        token,
        cancelEmail,
        user,
    }

    return authneticationModule; 
};

export default useAuthenticationModule; 