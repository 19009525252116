import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonIcon, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToolbar } from '@ionic/react';
import RosterCardComponent from '../components/RosterCardComponent';

import './Rosters.css';

import { Api } from '../services/api';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { calendarOutline, sadOutline } from 'ionicons/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCalendarDays, faHistory } from '@fortawesome/free-solid-svg-icons';


interface LoginPageProps extends RouteComponentProps {
  api: Api
}

const Rosters: React.FC<LoginPageProps> = ({api}) => {
  const history = useHistory();

  return (
    <IonPage>
      <IonHeader >
        <IonToolbar color="custom-toolbar" style={{backgroundColor : '#3f8dcc'}}>
          <IonTitle><FontAwesomeIcon icon={faCalendarDays} />   Rosters</IonTitle>
          <IonButton
              slot="secondary"
              fill="clear"
              shape="round"
              color="dark"
              onClick={() => history.push('/history')}
            >
              <FontAwesomeIcon icon={faHistory} />
            </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="custom-content">
        <IonRefresher slot="fixed" onIonRefresh={(e) => api.rosters.doRefresh(e, api.authentication?.user?.id)}>
            <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {
          (api.rosters?.filteredRosters?.length)? 
          (<>
            {api.rosters?.filteredRosters?.map((item, index) => (
              <div key={index}>
                <RosterCardComponent id={index} roster={item} />
              </div>
            )
            )}
          </>) 
          : 
          (<>
            <div className="text-center mt-4 text-muted">
              <b>No Rosters Yet!!</b> <IonIcon icon={sadOutline} />
            </div>
          </>)
        }
      </IonContent>
    </IonPage>
  );
};

export default Rosters;
