import {
    IonContent,
    IonButton,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonImg,
} from "@ionic/react";
import "./FileViewComponent.css";
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { close, download } from "ionicons/icons";
import '@react-pdf-viewer/core/lib/styles/index.css';


interface ContainerProps {
    fileName: string;
    fileContent: string;
    fileType: string;
    onDismiss: () => void;
}

const FileViewComponent: React.FC<ContainerProps> = ({
    fileName,
    fileContent,
    fileType,
    onDismiss
}) => {

    const handleDownload = () => {
       
        const link = document.createElement('a');
        link.href = fileContent;
        link.setAttribute('download', fileName); // Use the file name
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.parentNode?.removeChild(link);
        window.URL.revokeObjectURL(fileContent);
    };


    return (
        <IonPage>
            <IonHeader >
                <IonToolbar color="custom-toolbar" style={{ backgroundColor: '#3f8dcc' }}>
                    <IonButton slot="start" color="dark" fill="clear" onClick={handleDownload}><IonIcon icon={download} /></IonButton>
                    <IonTitle>{fileName}</IonTitle>
                    <IonButton slot="end" color="dark" fill="clear" onClick={onDismiss}><IonIcon icon={close} /></IonButton>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonContent>
                    {fileType.startsWith('image/') && (
                        // <img src={fileContent} alt="Displayed" style={{ width: '100%', height: 'auto' }} />
                        <IonImg src={fileContent} alt="Displayed" style={{ width: '100%', height: 'auto' }} />
                    )}
                    {fileType === 'application/pdf' && (
                        <div>
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                            <div className={`pdf-container`}>
                                <Viewer fileUrl={fileContent} defaultScale={SpecialZoomLevel.ActualSize} />
                            </div>
                            </Worker>
                        </div>

                    )}
                </IonContent>
            </IonContent>
        </IonPage>
    );
};

export default FileViewComponent;
