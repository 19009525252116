import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonIcon, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToolbar } from '@ionic/react';
import RosterCardApprovalComponent from '../components/RosterCardApprovalComponent';

import './RostersApproval.css';

import { Api } from '../services/api';
import { RouteComponentProps } from 'react-router-dom';
import { sadOutline } from 'ionicons/icons';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToolbarComponent from '../components/ToolbarComponent';

interface LoginPageProps extends RouteComponentProps {
  api: Api
}

const RostersApproval: React.FC<LoginPageProps> = ({api}) => {


  return (
    <IonPage>
      <IonHeader >
        <ToolbarComponent icon={faCalendarCheck} title='Approval' backbutton={false} backlink='' />

      </IonHeader>
      <IonContent fullscreen className="custom-content">
        <IonRefresher slot="fixed" onIonRefresh={(e) => api.rosters.doRefresh(e, api.authentication?.user?.id)}>
            <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {
          (api.rosters?.approvalRosters?.length)? 
          (<>
            {api.rosters?.approvalRosters?.map((item, index) => (
              <div key={index}>
                <RosterCardApprovalComponent id={index} roster={item} api={api} />
              </div>
            )
            )}
          </>) 
          : 
          (<>
            <div className="text-center mt-4 text-muted">
              <b>No Rosters Yet!!</b> <IonIcon icon={sadOutline} />
            </div>
          </>)
        }
      </IonContent>
    </IonPage>
  );
};

export default RostersApproval;
