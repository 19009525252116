import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  IonIcon,
} from "@ionic/react";
import "./RosterCardComponent.css";
import { useHistory } from 'react-router-dom';
import { Roster } from "../interfaces/rosterinterfaces.ds";
import moment from "moment";
import { businessOutline, hammerOutline, locationOutline, timeOutline } from "ionicons/icons";
import { faHatHard, faUserClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


interface ContainerProps {
  id: number;
  roster: Roster
}

const RosterCardComponent: React.FC<ContainerProps> = ({
  id,
  roster
}) => {
    const history = useHistory();
    
    const handleTimeCardClick = (RosterId: number) => {
      
        // Navigate to the time card detail page for the clicked time card
        // You can use a routing library like react-router-dom for navigation
        history.push(`/rosters/${RosterId}`);
    };

    const cardColor = (date: any) => {
      let today = moment(new Date()).format("YYYY-MM-DD");
      let color = '';
      
        if(roster?.completed){
          if(roster?.job_allocation?.supervisor_id !=null && roster?.timesheet_entries[0]?.supervisor_accepted == null){
            color = "background-unsigned";
          }else if(roster?.job_allocation?.supervisor_id !=null && roster?.timesheet_entries[0]?.supervisor_accepted  != null && roster?.timesheet_entries[0]?.supervisor_accepted  == 0){
            color = "background-supervisor-rejected";
          }else{
            color = "background-today";
          }
        }else{
          if(moment(date).isBefore(today)){
            color = "background-incomplete"
          }else{
            color = "background-accepted";
          }
        }

        if(moment(date).format("YYYY-MM-DD") < today){
          color += " background-dim"
        }

        return color;
    }
    
    return (
        <IonCard key={id} onClick={() => handleTimeCardClick(roster.id)}>
            <IonCardContent className={cardColor(roster.date) + ' padding-0'}>
              <IonGrid className="container">
                <IonRow>
                  <IonCol className="col-2">
                    <div className="date">
                      <div className="day">{moment(roster.date).format("D")}</div>
                      <div className="month text-bold">{moment(roster.date).format("MMM")}</div>
                    </div>
                  </IonCol>
                  <IonCol className="col-10">
                    <div className="event-details">
                      <div className="time"><IonIcon aria-hidden="true" icon={timeOutline} /> {moment(roster?.start_time, "HH:mm:ss").format("hh:mm A")} - {moment(roster?.end_time, "HH:mm:ss").format("hh:mm A")}</div>
                      {roster?.timesheet_entries?.length > 0 && <div className="time"><FontAwesomeIcon icon={faUserClock} /> {moment(roster?.timesheet_entries[0]?.start_time, "HH:mm:ss").format("hh:mm A")} - {moment(roster?.timesheet_entries[0]?.end_time, "HH:mm:ss").format("hh:mm A")}</div>}
                      {(roster.job_allocation?.site_address) && <div className="location"> <IonIcon aria-hidden="true" icon={locationOutline} /> {roster.job_allocation?.site_address}</div>}
                      <div className="client"><IonIcon aria-hidden="true" icon={businessOutline} /> {roster.job_allocation?.client}</div>
                      <div className="client"><IonIcon aria-hidden="true" icon={hammerOutline} /> {roster.job_task?.job_title}</div>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
        </IonCard>
    );
};

export default RosterCardComponent;
