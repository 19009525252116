import React, { useState } from 'react';
import { IonContent, IonLoading, IonInput, IonButton, IonItem, IonToast, IonCard, IonCardContent, IonCardHeader, IonItemDivider, IonText, IonButtons, IonIcon,IonCol, IonGrid, IonRow, IonPage, useIonAlert, IonBadge } from '@ionic/react';
import logoImage from '../assets/img/icon.png';
import './Login.css';
import validator from 'validator';
import {Api} from '../services/api';
import { RouteComponentProps } from 'react-router';

import { create, earth, earthOutline, ellipsisHorizontal, ellipsisVertical, helpCircle, logIn, reload, search, star } from 'ionicons/icons';
import { getHostName } from './Settings';

interface LoginPageProps extends RouteComponentProps {
  api: Api
}

const LoginPage: React.FC<LoginPageProps> = ({history, match, api}) => {
  const [present] = useIonAlert();

  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const onSendEmail = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    const phoneRegex = /^[0-9]{10,}$/;
    if(!validator.isEmail(email) && !phoneRegex.test(email)){
      present('Please enter valid email or phone number', [{ text: 'Ok' }]);
      return;
    }

    const response = await api.authentication.sendEmail(email);

    if(response){
      setSent(true);
    }else{
      present('Issue sending email, please try again.', [{ text: 'Ok' }]);
    }
    setLoading(false);
    return false;

  };

  const onSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    
    if(!code){
      present('Please enter the code', [{ text: 'Ok' }]);
      return;
    }

    const responce = await api.authentication.sendCode(code);
    if(!responce){
      present('Code not valid, please try again.', [{ text: 'Ok' }]);
    }
    setLoading(false);
    return false;
  };



  return (
    <IonPage>
        <IonContent className="bg-light  background-img">
            <div className="container">
            <IonCard>
                <IonCardHeader>
                    <div className="text-center">
                        <img src={logoImage} alt="Logo" className="img-fluid" />
                    </div>
                    
                </IonCardHeader>
                
                <div  className="text-center">
                    <IonText color="medium" >
                        <h6  className="text-center">
                            <strong>
                                <span>Roster and Timesheet</span><br/>
                                <span>Management Online</span>
                            </strong>
                        </h6>
                    </IonText>
                </div>
               
                <IonItemDivider />
                {(sent) && (<IonText color="medium" >
                      <p className="text-center">
                              <span>Please check the email</span>
                      </p>
                  </IonText>)}
                <IonCardContent>
                {(api.portalAPI) &&  <div  className="d-flex justify-content-center"><IonBadge className='d-flex align-items-center' color="primary"><IonIcon icon={earth} size="small"></IonIcon><span style={{fontSize: 16}}>{getHostName(api.portalAPI)}</span></IonBadge></div>}

                {sent === false ? (
                  <>
                    <IonInput
                          type="email"
                          fill="solid"
                          label="Email or Phone Number"
                          value={email}
                          labelPlacement="floating"
                          helperText="Enter a valid email or phone number"
                          errorText="Invalid email/phone number"
                          onIonInput={(e) => setEmail(e.detail.value!)}
                          clearInput
                      ></IonInput>

                      <IonGrid>
                          <IonRow class="ion-justify-content-center">
                              <IonButton color="tertiary" fill="solid" onClick={(e) => {onSendEmail(e)}}>
                                  <IonIcon slot="start" icon={logIn}></IonIcon>
                                      Login
                              </IonButton>
                          </IonRow>
                          <IonRow class="ion-justify-content-center">
                              <IonButton color="medium" size='small' fill="solid" onClick={(e) => {api.setisPortalAuth(false)}}>
                                  <IonIcon slot="start" icon={reload}></IonIcon>
                                      Different portal? Go Again!
                              </IonButton>
                          </IonRow>
                      </IonGrid>
                    </>
                ):(
                  <>
                    <IonInput
                          type="text"
                          fill="solid"
                          value={code}
                          label="Access Code"
                          labelPlacement="floating"
                          helperText="Enter the access code"
                          errorText="Invalid access code"
                          onIonInput={(e) => setCode(e.detail.value!)}
                          clearInput
                      ></IonInput>

                      <IonGrid>
                          <IonRow class="ion-justify-content-center">
                              <IonButton color="tertiary" fill="solid" onClick={(e) => {onSubmit(e)}}>
                                  <IonIcon slot="start" icon={logIn}></IonIcon>
                                      Login
                              </IonButton>
                          </IonRow>
                          <IonRow class="ion-justify-content-center">
                              <IonButton color="medium" size='small' fill="solid" onClick={(e) => {setSent(false)}}>
                                  <IonIcon slot="start" icon={reload}></IonIcon>
                                      Didn't get the code? Try Again!
                              </IonButton>
                          </IonRow>
                      </IonGrid>
                    </>
                )}
                </IonCardContent>
            </IonCard>
            </div>
            <IonLoading
              isOpen={loading}
              onDidDismiss={() => setLoading(false)}
              message={'Please wait...'}
            />
        </IonContent>
    </IonPage>
    
  );
};

export default LoginPage;
