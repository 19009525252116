import { IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToolbar, useIonModal } from '@ionic/react';
import RosterCardComponent from '../components/RosterCardComponent';
import './Timesheet.css';
import { RouteComponentProps, useHistory } from 'react-router';
import { Api } from '../services/api';
import React, { useEffect, useState } from 'react';
import { sadOutline } from 'ionicons/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCalendarPlus, faClockFour, faHandshakeAlt, } from '@fortawesome/free-solid-svg-icons';
import ToolbarComponent from '../components/ToolbarComponent';
import RosterAddComponent from '../components/RosterAddComponent';
import moment from 'moment';


interface LoginPageProps extends RouteComponentProps {
  api: Api
}

const Timesheet: React.FC<LoginPageProps> = ({api}) => {

  const history = useHistory();


  useEffect(()=>{    
    if(api.authentication?.user?.id){
      api.rosters.getRosters(api.authentication?.user?.id)

    }
  }, []);

  const notApprovedRosters = () => {
    if(api.rosters?.approvalRosters && api.rosters?.approvalRosters?.length > 0){
      const filteredArray = api.rosters?.approvalRosters?.filter((item, index)=>{
        return item.accepted === null && (moment(item.date).format("YYYY-MM-DD") >=  moment(new Date()).format("YYYY-MM-DD"));
      });
      return filteredArray.length;
    }
    return 0;
  }

  const [present, dismiss] = useIonModal(RosterAddComponent, {
    api,
    onDismiss: () => {dismiss()},
  });
  
  return (
    <IonPage>
      <IonHeader >
        <ToolbarComponent icon={faClockFour} title='Timesheet' backbutton={false} backlink=''/>
      </IonHeader>
      <IonContent fullscreen className="custom-content">
        <IonRefresher slot="fixed" onIonRefresh={(e) => api.rosters.doRefresh(e, api.authentication?.user?.id)}>
            <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <div className="image-section">
          <div className="content-container">
            <h6><FontAwesomeIcon icon={faHandshakeAlt} shake /> Welcome <b>{api.authentication.user?.name},</b></h6>
            <p>You have <b className='fade-in-out'>{api.rosters?.rosterToday?.length} </b> activity today</p>
          </div>
        </div>
       {(notApprovedRosters() > 0) && (<div className='bg-danger p-2 text-center font-weight-bold text-white' onClick={()=> history.push('/approval')}><FontAwesomeIcon icon={faBell} shake /> <span className='fade-in-out'>You have {notApprovedRosters()} {(notApprovedRosters() > 1) ? 'dockets': 'docket'} waiting for approval!</span></div>)}

        {
          (api.rosters?.rosterToday?.length)? 
          (<>
            {api.rosters?.rosterToday?.map((item, index) => (
                <div key={index}>
                  <RosterCardComponent id={index} roster={item} />
                </div>
              )
            )}
          </>) 
          : 
          (<>
            <div className="text-center mt-4 text-muted">
              <b>No Rosters Today!!</b> <IonIcon icon={sadOutline} />
            </div>
          </>)
        }
        {/**/}
        
      </IonContent>
      {<IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton onClick={() => present()}>
            <FontAwesomeIcon icon={faCalendarPlus} size="lg" />
          </IonFabButton>
        </IonFab>}
    </IonPage>
  );
};

export default Timesheet;
